<template>
    <div class="coupons-list">
      <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
      <div class="main-content">
        <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="绑定门店" :name="0">
            <shopBindList></shopBindList>
          </el-tab-pane>
          <el-tab-pane label="关联商品" :name="1">
            <serveList></serveList>
          </el-tab-pane>
          <el-tab-pane label="核销记录" :name="2">
            <logList></logList>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </template>
  
  <script lang="js">
  import { defineComponent, reactive, ref } from 'vue'
  import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
  import { breadcrumbList } from './config'
  import shopBindList from './cpns/shopbind-list/index.vue'
  import serveList from './cpns/serve-list/index.vue'
  import logList from './cpns/log-list/index.vue'
  
  export default defineComponent({
    props: {
  
    },
    components: {
      Breadcrumb,
      shopBindList,
      serveList,
      logList
    },
    setup() {
  
      const activeName = ref(0)
    //   const tabStatusList = reactive([{
    //     value: 0,
    //     label: '绑定门店'
    //   },{
    //     value: 1,
    //     label: '关联商品'
    //   }])
      const handleClick = () => {
      }
  
      return {
        breadcrumbList,
        activeName,
        // tabStatusList,
        handleClick
      }
  
    }
  })
  </script>
  
  <style scoped lang="less">
  .coupons-list {
    min-width: 1080px;
  
    .main-content {
      padding: 20px;
      margin: 20px;
      background-color: #fff;
  
      &:deep(.demo-tabs) {
        margin-top: 20px;
      }
    }
  }
  </style>
  
  
  
  
  