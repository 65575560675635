<template>
  <div class="coupons-list">
    <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom:20px;">
      <el-form ref="ruleFormRef" label-position="left" :model="ruleForm"
      class="demo-ruleForm">
          <el-form-item prop="dateList" label="">
            <el-date-picker @change="handleDateChange" v-model="dateList" value-format="YYYY-MM-DD" type="daterange" range-separator="到"
            start-placeholder="开始时间" end-placeholder="结束时间" />
          </el-form-item>
          <el-form-item prop="shopId" label="" style="margin: 0 20px;">
              <el-select v-model="ruleForm.shopId" class="m-2" placeholder="所属门店" clearable>
                  <el-option v-for="item in shopList" :key="item.shopName" :label="item.shopName" :value="item.shopId" />
              </el-select>
          </el-form-item>
          <el-form-item prop="keyword" label="">
              <el-input prefix-icon="search" v-model="ruleForm.keyword" placeholder="输入关键字" clearable></el-input>
          </el-form-item>
          <div class="search-btn">
            <el-button @click="searchClick" size="" type="primary">搜索</el-button>
          </div>
      </el-form>
      <el-button @click="exportClick" size="" type="primary">导出</el-button>
    </div>
    <el-table :data="tabeleData" style="width: 100%">
      <el-table-column align="center" type="index" label="序号" />
      <el-table-column align="center" prop="userName" label="姓名/手机" width="200px">
        <template #default="{row}">
          {{ row.userName }}({{ row.userPhone }})
        </template>
      </el-table-column>
      <el-table-column align="center" prop="tripartiteId" label="美团点评商品ID"  width="150px" />
      <el-table-column align="center" prop="tripartiteServeName" label="美团点评商品名称"  width="200px" />
      <el-table-column align="center" prop="orderNo" label="订单号" width="210px" />
      <el-table-column align="center" prop="couponNo" label="券号" width="200px" />
      <el-table-column align="center" prop="serverPrice" label="商品原价" width="120px">
        <template #default="{row}">
          ¥{{row.serverPrice||'-'}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sourcePrice" label="大众原价" width="120px">
        <template #default="{row}">
          ¥{{row.sourcePrice}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="discountPrice" label="优惠金额" width="120px">
        <template #default="{row}">
          ¥{{row.discountPrice}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="payPrice" label="实收金额" width="120px">
        <template #default="{row}">
          ¥{{row.payPrice}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="shopName" label="验券门店" width="180px" />
      <el-table-column align="center" prop="createTime" label="验证时间" width="180px" />
      <el-table-column align="center" prop="status" label="券状态">
        <template #default="scope">
            <p v-if="scope.row.status==1">未使用</p>
            <p v-if="scope.row.status==2">已使用</p>
            <p v-if="scope.row.status==3">已过期</p>
            <p v-if="scope.row.status==4">已作废</p>
            <p v-if="scope.row.status==5">待领取</p>
          </template>
      </el-table-column>
      <el-table-column prop="date" label="操作" align="center" width="100px" fixed="right">
        <template #default="scope">
          <el-button v-if="scope.row.status==1" type="text" size="small" style="color: red;" @click="handleRemoveClick(scope.row.userCouponId)">作废</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="el-pagination">
      <el-pagination v-model:currentPage="currentPage.page" v-model:page-size="currentPage.pagesize"
          :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper" :total="total"
          @size-change="handleSizeChange" @current-change="handleCurrentChange" :pager-count="3" />
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted ,watch,computed} from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { useRouter } from 'vue-router'
import { popup } from '@/utils/pop-up'
import { ElMessage } from 'element-plus'
import { getShopList } from '@/service/main/target'
import {getExchangeCouponRecordList
} from '@/service/main/customer'
import { stopUserCoupon } from '@/service/main/goods'
import {exportMethod} from '@/utils/export'
export default defineComponent({
  props: {
    activeName: {
      type: Number,
      default: 0
    },
  },
  components: {
    PageContent
  },
  setup(props) {
    const router = useRouter()

    const shopList = ref([])
    const tabeleData = ref([])
    const ruleForm = reactive({
      shopId: ''
    })
    const total = ref(0)
    const currentPage = reactive({
      page: 1,
      pagesize: 10,
      type:1
    })
    watch(currentPage, () => {
      getOrderList()
    }, {
      deep: true
    })

    const exportClick = () =>{
      let params = {...currentPage,...ruleForm}
      exportMethod('/coupon/web/downloadExchangeCouponRecord','美团点评兑换券记录',params)
    }

    const searchClick = () =>{
      getOrderList()
    }
    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }
    const dateList = ref([])
    const handleDateChange = value => {
      if (value) {
        ruleForm.startDate = value[0]
        ruleForm.endDate = value[1]
      }else{
        ruleForm.startDate = undefined
        ruleForm.endDate = undefined
      }
    }
    //删除
    const handleRemoveClick = (id) => {
      popup('是否确认作废该兑换券?', async() => {
        let res = await stopUserCoupon(id)
        if (res.code==0) {
          ElMessage.success({
            message: '作废成功!',
            type: 'success'
          })
          refresh()
        }else{
          ElMessage.error({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
    const refresh = () => {
      getOrderList()
    }
    const getOrderList = async() => {
        let data = await getExchangeCouponRecordList({...currentPage,...ruleForm})

        tabeleData.value = data.data.list
        total.value = Number(data.data.total)
    }
    const initPage = async () => {
        const res = await getShopList(10000)
        shopList.value = res.data.list
        getOrderList()
    }
    initPage()

    return {
      handleRemoveClick,
      shopList,
      refresh,
      ruleForm,
      getOrderList,
      tabeleData,
      dateList,
      handleDateChange,
      handleCurrentChange,
      currentPage,
      handleSizeChange,
      total,
      searchClick,
      exportClick
    }

  }
})
</script>

<style scoped lang="less">
.coupons-list {
  // margin-top: 20px;
  .demo-ruleForm{
    display: flex;
  }
  .el-form-item{
    margin-bottom: 0 !important;
  }
  .search-btn{
    margin-left: 20px;
  }
  .line {
    display: inline-block;
    margin: 0 6px;
  }
  .border{
    color: #ccc;
    padding: 0 6px;
  }
  .example-showcase .el-dropdown-link {
    cursor: pointer;
    color: var(--el-color-primary) !important;
    display: flex;
    align-items: center;
  }
  &:deep(.el-table) {
    // font-size: 12px;
    thead.is-group th.el-table__cell{
        background-color: rgba(239, 239, 239, 1) !important;
        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }


    .el-table__header-wrapper {
      
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 14px !important;
          font-weight: 500 !important;
        }
      }

    }
    th.el-table__cell{
      background-color: rgba(239, 239, 239, 1) !important;
    }
  }
  .reserve-project-list {
      .serve_flex{
          display: flex;
      }
      .active {
          background-color: #fff !important;
          color: rgb(133, 88, 250);
          font-weight: 550;
      }
      .category-list {
          width: 100px;

          .item {
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          line-height: 45px;
          background-color: rgb(239, 239, 239);
          border-bottom: 1px solid rgb(255, 255, 255);
          min-width: 100px;
          cursor: pointer;
          }
      }

      .reserve-top-form {
          display: flex;
          justify-content: space-between;
          background-color: rgb(239, 239, 239);
          padding: 10px 20px;

          .reserve-el-select {
          width: 45%;
          }
      }

      &:deep(.serve-list-wrap) {
          padding-right: 10 !important;
      }

      .empty {
          width: 100%;
          display: flex;
          justify-content: center;
      }
  }
  .shop-list {
    width: 520px;
    text-align: center;
    .serve-list {
      .item {
        display: flex;
        height: 40px;
        align-items: center;
        border-bottom: 1px solid #efefef;
        .icon{
            width: 80px;
            display: flex;
            justify-content: center;
            color: #ccc;
          }

        .left {
        //   width: 330px;
          display: flex;
          height: 40px;
          align-items: center;
          .name{
            width: 230px;
          }
        }

        .right {

          .checkbox {
            display: flex;

            div {
                width: 110px;
            }

            .salePrice {
              color: rgb(255, 105, 74);
            }
          }
        }
      }
    }

    .titls {
      display: flex;
    //   margin-bottom: 20px;

      div {
        width: 110px;
        line-height: 40px;
        background-color: rgb(239, 239, 239);
      }
    }

  }
  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
